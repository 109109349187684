//
// Helpers
//
function $All(selector, context) {
	var arr = [];
	arr.push.apply(arr, (context || document).querySelectorAll(selector));
	return arr;
}

function $(selector, context) {
	return (context || document).querySelector(selector);
}


//
// forEach Polyfill
// https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
//
if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = function (callback, thisArg) {
		thisArg = thisArg || window;
		for (var i = 0; i < this.length; i++) {
			callback.call(thisArg, this[i], i, this);
		}
	};
}


//
// Element.closest() Polyfill
// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
//
if (!Element.prototype.matches) {
	Element.prototype.matches =
	Element.prototype.msMatchesSelector ||
	Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
	var el = this;

	do {
		if (Element.prototype.matches.call(el, s)) return el;
		el = el.parentElement || el.parentNode;
	} while (el !== null && el.nodeType === 1);
	return null;
	};
}

//
// Element.remove() Polyfill
// https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
//
(function (arr) {
	arr.forEach(function (item) {
	  if (item.hasOwnProperty('remove')) {
		return;
	  }
	  Object.defineProperty(item, 'remove', {
		configurable: true,
		enumerable: true,
		writable: true,
		value: function remove() {
		  if (this.parentNode !== null)
			this.parentNode.removeChild(this);
		}
	  });
	});
  })([Element.prototype, CharacterData.prototype, DocumentType.prototype]);



//
// Get CSS Breakpoints Value
//
var breakpoint = {};
breakpoint.refreshValue = function () {
	this.value = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
};
window.addEventListener('resize', function(){
	breakpoint.refreshValue();
});
breakpoint.refreshValue();


//
// IE detection
// returns version of IE or false, if browser is not Internet Explorer
//
function detectIE() {
	var ua = window.navigator.userAgent;

	var msie = ua.indexOf('MSIE ');
	if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
	}

	var trident = ua.indexOf('Trident/');
	if (trident > 0) {
		// IE 11 => return version number
		var rv = ua.indexOf('rv:');
		return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
	}

	var edge = ua.indexOf('Edge/');
	if (edge > 0) {
		// Edge (IE 12+) => return version number
		return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
	}

	// other browser
	return false;
}

if (detectIE() != false){
	if( detectIE() == 16 ) {
		document.documentElement.classList.add('msie edge-16');
	}
	else if( detectIE() >= 17 ) {
		document.documentElement.classList.add('no-msie edge-17+');
	}
	else {
		document.documentElement.classList.add('msie');
	}
} else{
	document.documentElement.classList.add('no-msie');
}


//
// Remove .no-js class from html element if javascript is enabled
//
document.documentElement.className = document.documentElement.className.replace('no-js','js');



//
// Test for touchevent support and if not supported, attach .no-touch class to the HTML tag.
// https://www.prowebdesign.ro/how-to-deal-with-hover-on-touch-screen-devices/
//
if (!('ontouchstart' in document.documentElement)) {
	document.documentElement.className += ' no-touch';
}



var mainMenu 	 = document.querySelector('.js-menu');
var mainMenuToggleLinks = $All('.js-toggle-menu__listitem > .js-disabled-link');
var mainMenuDisabledLinks = $All('.js-disabled-link');
var mainMenuToggleInput = document.querySelector('.js-showmenu__input');
var hash = window.location.hash;



//
// Open Desktop Overlay Menu Items
//
mainMenuToggleLinks.forEach(function(el) {
	el.addEventListener('click', function(event) {
		event.preventDefault();

		var openItem = document.querySelector('.js-toggle-menu__listitem.is-open');

		if(window.innerWidth >= 800) {
			if (el.parentNode.classList.contains('is-open')) {
				el.parentNode.classList.remove('is-open');
			} else {
				if (openItem !== null) {
					openItem.classList.remove('is-open');
				}

				// close all other open Menus
				mainMenuToggleLinks.forEach(function(openElements) {
					openElements.parentNode.classList.remove('is-open');
				});

				el.parentNode.classList.add('is-open');
			}
		}
	});
});



//
// Detect Click outside of Overlay Menu
//
document.addEventListener('click', function(event) {
	if (event.target.closest('.js-toggle-menu__listitem, .submenu')) return;

	var openItem = document.querySelector('.js-toggle-menu__listitem.is-open');
	if (openItem !== null) {
		openItem.classList.remove('is-open');
	}
});



//
// Detect ESC Key to close Overlay Menu
//
document.addEventListener('keyup', function(event) {
	var key = event.which || event.keyCode;
	if (key === 27) {
		var openItem = document.querySelector('.js-toggle-menu__listitem.is-open');
		if (openItem !== null) {
			openItem.classList.remove('is-open');
		}
	}
});



//
// Close Mobile Menu on window.resize
//
window.addEventListener('resize', function(){
	if (mainMenuToggleInput !== null && (mainMenuToggleInput.checked === true) ) {
		mainMenuToggleInput.checked = false;
	}
});



//
// Open Desktop Overlay Menu Items
//
mainMenuDisabledLinks.forEach(function(link) {
	link.addEventListener('click', function(event) {
		event.preventDefault();
	});
});



//
// Map Overlay Marker Hover
//
const $mapText   = $All('.js-map__text');
const $mapMarker = $All('.js-map__marker');

$mapText.forEach(function(el, i){
	const hoverImage = document.getElementById(el.getAttribute('data-overlay'));

	el.addEventListener('mouseenter', function(e) {
		const number = el.getAttribute('data-number');
		$mapMarker[i].classList.add('hover');

		if (hoverImage !== null) {
			hoverImage.classList.add('active');
		}
	});

	el.addEventListener('mouseleave', function(e) {
		$mapMarker[i].classList.remove('hover');

		if (hoverImage !== null) {
			hoverImage.classList.remove('active');
		}
	});
});

$mapMarker.forEach(function(el, i){
	const hoverImage = document.getElementById(el.getAttribute('data-overlay'));

	el.addEventListener('mouseenter', function(e) {
		$mapText[i].classList.add('hover');

		if (hoverImage !== null) {
			hoverImage.classList.add('active');
		}
	});

	el.addEventListener('mouseleave', function(e) {
		$mapText[i].classList.remove('hover');

		if (hoverImage !== null) {
			hoverImage.classList.remove('active');
		}
	});
});





//
// Accordion
//
var accordion 		  = $All('.js-accordion');
var accordionButton   = $All('.js-accordion__button');
var accordionCollapse = $All('.js-accordion__collapse');

function toggleAccordionItem(el) {
	var isopen  = el.getAttribute('aria-expanded');
	var parent  = el.parentNode.parentNode;
	var title   = parent.querySelector('.accordion__title, .timeline__title');
	var content = parent.querySelector('.accordion__collapse, .timeline__collapse');

	if (isopen == 'false') {
		el.setAttribute('aria-expanded', true);
		parent.classList.add('is--open');
		title.classList.add('is--open');
		el.blur();

		content.style.height = 'auto';
		var height = content.clientHeight + 'px';
		content.style.height = '0px';

		setTimeout(function () {
			content.style.height = height;
			setTimelineImages(parent);

			content.addEventListener('transitionend', function () {
				if (parent.querySelector('.js-timeline__media') !== null) {
					parent.querySelector('.js-timeline__media .image').style.opacity = '1';
				}
			}, {
				once: true
			});
		}, 0);
	} else if (parent.querySelector('.js-timeline__media') !== null) {
		parent.querySelector('.js-timeline__media .image').style.opacity = '0';

		content.style.height = '0px';
		parent.parentNode.style.minHeight = '0';

		setTimeout(function () {
			el.setAttribute('aria-expanded', false);
			el.blur();
			parent.classList.remove('is--open');
			title.classList.remove('is--open');
		}, 500);
	} else {
		content.style.height = '0px';
		content.addEventListener('transitionend', function () {
			el.setAttribute('aria-expanded', false);
			el.blur();
			parent.classList.remove('is--open');
			title.classList.remove('is--open');
		}, {
			once: true
		});
	}
}

if (accordion.length > 0) {
	accordionButton.forEach(function(el){
		el.addEventListener('click', function(e) {
			e.preventDefault();
			toggleAccordionItem(el);
		});
	});


	// check if hash has changed
	function locationHashChanged() {
		const el = document.getElementById(location.hash.substring(1));
		if (el && el.classList.contains('js-accordion__button')) {
			toggleAccordionItem(el);
		}
	}
	window.onhashchange = locationHashChanged;


	// check if uri has hash
	if (hash != '') {
		const el = document.getElementById(hash.substring(1));
		if(el && el.classList.contains('js-accordion__button')) {
			toggleAccordionItem(el);
		}
	}
}



//
// Timeline
//
const timelinewrapper = $('.js-timelinewrapper');
const timeline = $All('.js-timeline');
const verticalline = $All('.js-timeline__verticalline');
const timelineAndMilestones = $All('.js-timeline, .js-timeline__milestone');

function setTimelineImages(parent) {
	if( parent.getElementsByClassName('js-timeline__media')[0] != null && breakpoint.value >= 800 ){
		const timelineItem = parent.parentNode;
		const imagewrapper = timelineItem.getElementsByClassName('js-timeline__media')[0];
		const image = imagewrapper.getElementsByClassName('image')[0];
		const buttonHeight = timelineItem.getElementsByClassName('timeline__title')[0].clientHeight;
		const imagewrapperHeight = imagewrapper.clientHeight;

		timelineItem.style.minHeight = imagewrapperHeight + buttonHeight + 'px';
		imagewrapper.style.top = buttonHeight + 'px';
		imagewrapper.style.height = imagewrapperHeight + 'px';
	}
}

function setTimelineImagesOnResize() {
	const openAccordion = $All('.js-accordion.is--open');
	if(openAccordion != null){
		openAccordion.forEach(function(el){
			if(el.getElementsByClassName('js-timeline__media')[0] != null){
				if(breakpoint.value < 800) {
					el.parentNode.style.minHeight = 'auto';
					el.getElementsByClassName('js-timeline__media')[0].style.cssText = 'top: auto; height: auto;';
					el.getElementsByClassName('js-accordion__collapse')[0].style.height = 'auto';
				} else {
					const imagewrapper = el.getElementsByClassName('js-timeline__media')[0];
					const imagewrapperHeight = imagewrapper.clientHeight;
					const buttonHeight = el.getElementsByClassName('timeline__title')[0].clientHeight;

					el.parentNode.style.minHeight = imagewrapperHeight + buttonHeight + 'px';
					el.getElementsByClassName('image')[0].classList.add('is--visible');
				}
			}
		});
	}
}



function startTimeline() {
	const counterTimelines = timeline.length;
	const firstButtonHeight = timeline[0].getElementsByClassName('timeline__title')[0].clientHeight;
	const timelinewrapperHeight = timelinewrapper.clientHeight;
	const verticallinePosY = ((firstButtonHeight / 2) - 2);
	const lastTimelineItemHeight = timelinewrapper.getElementsByClassName('js-timeline')[counterTimelines - 1].clientHeight;
	let verticallineHeight = timelinewrapperHeight - verticallinePosY;

	// Open item on Page Load
	timeline.forEach(function(el){
		if(el.classList.contains('is--openOnPageload')) {
			toggleAccordionItem(el.getElementsByClassName('js-accordion__button')[0]);
			el.classList.remove('is--openOnPageload');
		}
	});

	if (breakpoint.value >= 800) {
		// verticallineHeight = '100%';
		verticalline[0].style.top = verticallinePosY + 'px';
		verticallineHeight = 'calc(100% - ' + lastTimelineItemHeight + 'px)';
	} else {
		verticalline[0].style.top = '-' + verticallinePosY + 'px';
		verticallineHeight = 'calc(100% + 42px - 80px)';
	}


	if (verticalline[0].classList.contains('is--hidden')) {
		// anime.js Vertical Line Animation
		anime({
			targets: '.js-timeline__verticalline.is--hidden',
			height: verticallineHeight,
			duration: 2000,
			easing: 'easeInOutExpo',
			complete: function() {
				verticalline[0].classList.remove('is--hidden')
				// verticalline[0].style.height = 'calc(100% - ' + verticallinePosY + 'px - ' + lastTimelineItemHeight + 'px)';
			}
		});

		// anime.js timeline
		let animeTimeline = anime.timeline({
			easing: 'easeOutExpo',
			duration: 500
		});

		timelineAndMilestones.forEach(function(el){
			animeTimeline.add({
				targets: el,
				opacity: 1,
				complete: function() {
					if(breakpoint.value >= 800) {
						if(!el.classList.contains('js-timeline__milestone')) {
							const branch = el.getElementsByClassName('timeline__branch')[0];
							anime({
								targets: branch,
								width: '50%',
								duration: 200,
								easing: 'easeInOutExpo',
							});
							timeline.forEach(function(el){
								const buttonHeight = el.getElementsByClassName('timeline__title')[0].clientHeight;
								const branch = el.getElementsByClassName('timeline__branch')[0];
								branch.style.top = ((buttonHeight / 2) - 2) + 'px';
							});
						}
					}
				}
			});
		});
	}
}



function updateTimelineOnResize() {
	const verticallinePosY = (timeline[0].getElementsByClassName('timeline__title')[0].clientHeight / 2);
	const timelineBranch = $All('.timeline__branch');

	if(breakpoint.value < 800) {
		timelineBranch.forEach(function(el){
			el.style.width = 0;
		});
		verticalline.forEach(function(el){
			el.style.top = '-' + verticallinePosY + 'px';
			el.style.height = 'calc(100% - 80px + ' + verticallinePosY + 'px)';
		});
	} else {
		timelineBranch.forEach(function(el){
			el.style.width = '50%';
		});
		verticalline.forEach(function(el){
			el.style.top = '-' + verticallinePosY + 'px';
			el.style.height = 'calc(100% - 80px)';
		});
	}

}


// Set Timeline Lines
if (timeline.length > 0) {
	if ('IntersectionObserver' in window) {
		function handleIntersection(entries) {
			entries.forEach(function(entry){
				if (entry.isIntersecting) {
					startTimeline();
				}
			});
		}
		const observer = new IntersectionObserver(handleIntersection, {
			threshold: 0.3,
		});
		observer.observe(timelinewrapper);
	} else {
		startTimeline();
	}
}

// Set Timeline Lines on Window Resize
window.addEventListener('resize', function(){
	if (timeline.length > 0) {
		setTimelineImagesOnResize();
		updateTimelineOnResize();
	}
});



//
// Carousel
//
var carousel 		 = $All('.js-carousel');
var carouselCollapse = $All('.js-carousel__collapse');
var carouselMedia    = $All('.js-carousel__media');

// Close all Elements at all Carousels
carouselCollapse.forEach(function(el){
	if(breakpoint.value >= 800 ) {
		el.setAttribute('data-height', el.getBoundingClientRect().height+'px');
	} else {
		el.setAttribute('data-height', 'auto');
	}
	el.style.height = 0;
});

// Open first Element at each Carousel
carousel.forEach(function(carouselElement){
	var FirstElementHeight = carouselElement.getElementsByClassName('js-carousel__collapse')[0].getAttribute('data-height');
	carouselElement.getElementsByClassName('js-carousel__collapse')[0].style.height = FirstElementHeight;
	carouselElement.getElementsByClassName('js-carousel__collapse')[0].setAttribute('aria-expanded', true);
	carouselElement.getElementsByClassName('js-carousel__title')[0].classList.add('is--open');
	carouselElement.getElementsByClassName('js-carousel__media')[0].setAttribute('style', 'opacity: 1; visibility: visible;');

	function openCarouselItem(el, i) {
		var parentList  = el.parentNode.parentNode;
		var parentCarousel = el.parentNode.parentNode.parentNode.parentNode;
		var elementHeight;

		if(breakpoint.value >= 800 ) {
			elementHeight = el.nextElementSibling.getAttribute('data-height');
		} else {
			var elementMediaHeight = el.nextElementSibling.getElementsByClassName('carousel__media')[0].getBoundingClientRect().height;
			var elementContentHeight = el.nextElementSibling.getElementsByClassName('carousel__content')[0].getBoundingClientRect().height;
			elementHeight = elementMediaHeight + elementContentHeight;
		}

		parentList.querySelectorAll('.js-carousel__collapse').forEach(function(el){
			el.style.height = 0;
		});

		parentList.querySelectorAll('.js-carousel__title').forEach(function(el){
			el.classList.remove('is--open');
		});

		parentCarousel.querySelectorAll('.js-carousel__media').forEach(function(el){
			el.setAttribute('style', 'opacity: 0; visibility: hidden;');
		});

		el.nextElementSibling.setAttribute('aria-expanded', true);
		el.nextElementSibling.setAttribute('style','height:'+elementHeight);
		el.classList.add('is--open');
		parentCarousel.getElementsByClassName('js-carousel__media')[i].setAttribute('style', 'opacity: 1; visibility: visible;');
	}

	carouselElement.querySelectorAll('.js-carousel__title').forEach(function(el, i){
		el.addEventListener('click', function(e) {
			e.preventDefault();
			openCarouselItem(el, i);
			el.blur();
		});

		el.addEventListener('keypress', function(e) {
			var key = e.which || e.keyCode;
			if (key === 13) {
				openCarouselItem(el, i);
			}
		});
	});
});



//
// Pause all playing Videos
//
function pauseAllVideos() {
	var video = $All('video');
	var youtube = $All('.js-video.is--playing');

	if (video.length > 0) {
		video.forEach(function(el){
			el.pause();
		});
	}
}



//
// Special Videoplayer with Autostart Video
//
function playFullVideo(e) {
	pauseAllVideos();

	if (e.target.classList.contains('js-videobuttonwrapper')) {
		var videoteaser = e.target.previousElementSibling;
		var videofull   = e.target.previousElementSibling.previousElementSibling;
		e.target.remove();
	} else if (e.target.classList.contains('js-videobutton')) {
		var videoteaser = e.target.closest('.js-videoplayerautostart').querySelector('.js-videoteaser');
		var videofull   = e.target.closest('.js-videoplayerautostart').querySelector('.js-videofull');
		e.target.parentNode.remove();
	} else {
		console.log('Error playFullVideo.');
	}

	videoteaser.pause();
	videoteaser.removeAttribute('loop');
	videoteaser.removeAttribute('autoplay');
	videoteaser.classList.add('is--hidden');
	videofull.classList.remove('is--hidden');
	videofull.play();
}


var autostartVideoPlayer = $All('.js-videoplayerautostart');

autostartVideoPlayer.forEach(function(video){
	video.querySelector('.js-videobuttonwrapper').addEventListener('click', function(event) {
		if (video.querySelector('.js-videofull') != null) {
			playFullVideo(event, true);
		} else if (video.querySelector('.js-externalvideo') != null) {
			pasteVideoPlayer(event, true);
		} else {
			console.log('Error autostartVideoPlayer');
		}
	});

	video.querySelector('.js-videobuttonwrapper').addEventListener('keydown', function(event) {
		var key = event.which || event.keyCode;
		if (key === 13 || key === 32) {
			if(video.querySelector('.js-videofull') != null) {
				playFullVideo(event, true);
			} else if (video.querySelector('.js-externalvideo') != null) {
				pasteVideoPlayer(event, true);
			} else {
				console.log('Error autostartVideoPlayer');
			}
		}
	});
});


//
// Paste Video Player (Youtube, Vimeo or own html5 video)
//
function pasteVideoPlayer(e, videoPlaying) {
	e.preventDefault();
	e.stopPropagation();
	var videoplayer;
	var cookieplaceholder;

	pauseAllVideos();

	if (videoPlaying === false && e.currentTarget.classList.contains('js-videobuttonwrapper')) {
		// console.log('js-videobuttonwrapper click');
		videoplayer = e.target.closest('.js-video');
		cookieplaceholder = videoplayer.querySelector('.js-cookieplaceholder');
	} else if (videoPlaying === false && e.currentTarget.classList.contains('js-videobutton')) {
		// console.log('js-videobutton click');
		videoplayer = e.target.closest('.js-video');
		cookieplaceholder = videoplayer.querySelector('.js-cookieplaceholder');
	} else if (videoPlaying === true && (e.target.classList.contains('js-videobuttonwrapper') || e.target.classList.contains('js-videobutton'))) {
		// console.log('js-videobuttonwrapper or js-videobutton click @ Autoplay-Teaser');
		var videoContainer = e.target.closest('.js-videoplayerautostart');
		var videoteaser = videoContainer.querySelector('.js-videoteaser');
		videoteaser.pause();
		videoteaser.removeAttribute('loop');
		videoteaser.removeAttribute('autoplay');
		videoteaser.classList.add('is--hidden');
		videoplayer = videoContainer.querySelector('.js-externalvideo');
		videoContainer.querySelector('.js-videobuttonwrapper').remove();
		cookieplaceholder = videoContainer.querySelector('.js-cookieplaceholder');
	} else {
		console.log('Error pasting external Video.');
	}

	var ytID = videoplayer.getAttribute('data-youtube'),
		vimeoID = videoplayer.getAttribute('data-vimeo'),
		starttime = videoplayer.getAttribute('data-starttime') || 0,
		width   = videoplayer.offsetWidth,
		height  = videoplayer.offsetHeight,
		videoInner = videoplayer.querySelector('.video__inner'),
		src = false;

	if (ytID) {
		src = 'https://www.youtube-nocookie.com/embed/'+ ytID +'?rel=0&showinfo=0&autoplay=1&start='+ starttime;
	} else if (vimeoID) {
		src = 'https://player.vimeo.com/video/' + vimeoID + '?autoplay=1&color=ff0179#t='+ starttime +'s';
	} else {
		// Special Version for own html5 Videoplayer
		var html5video = videoplayer.querySelector('video');
		if (html5video){
			videoplayer.querySelector('.video__cover').remove();
			videoplayer.querySelector('.js-videobuttonwrapper').remove();
			html5video.play();
			html5video.setAttribute('tabindex', '0');
		} else {
			console.log('Error. Missing Video-ID.');
		}
	}

	if (src) {
		if (window.dwmCb && window.dwmCb.external) {
			cookieplaceholder && cookieplaceholder.remove();
			videoInner.innerHTML = '<iframe class="video__iframe" width="'+ width +'" height="'+ height +'" src="'+ src +'" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
			videoplayer.classList.toggle('is--playing');
		} else {
			cookieplaceholder && cookieplaceholder.classList.remove('is--hidden');
			document.addEventListener('dwm-cookies', function(e) {
				if (e.detail.external) {
					cookieplaceholder && cookieplaceholder.remove();
					videoInner.innerHTML = '<iframe class="video__iframe" width="'+ width +'" height="'+ height +'" src="'+ src +'" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
					videoplayer.classList.toggle('is--playing');
				}
			}, false);
		}
	}
}

var videoPlayer = $All('.js-video');

videoPlayer.forEach(function(video){
	video.querySelector('.js-videobuttonwrapper').addEventListener('click', function(event) {
		pasteVideoPlayer(event, false);
	});

	video.querySelector('.js-videobuttonwrapper').addEventListener('keydown', function(event) {
		var key = event.which || event.keyCode;
		if (key === 13 || key === 32) {
			pasteVideoPlayer(event, false);
		}
	});
});



//
// Videoplayer Start at current Time on Link click
//
var videoPlayerStarttimeLink = $All('a[href*="#"][href*="&"]');

function startVideoAtTimecode(el) {
	const parts = el.href.split('&');
	const targetVideo = parts[0].split('#')[1];
	const targetTime = parts[1];
	const videoPlayerContainer = document.getElementById(targetVideo);
	console.log('Starte Video #' + targetVideo + ' bei Sekunde ' + targetTime);

	const videoplayer = videoPlayerContainer.querySelector('.js-video');
	const  html5video = videoplayer.querySelector('video');

	if (html5video) {
		const buttonWrapper = videoPlayerContainer.querySelector('.js-videobuttonwrapper');
		if (buttonWrapper !== null){
			// Video wurde noch nicht gestartet
			// console.log('Play Button ist noch da.');
			videoplayer.querySelector('.video__cover').remove();
			buttonWrapper.remove();
		} else {
			// Video lief schon mal
			// console.log('Video wurde schon gestartet');
		}
		html5video.currentTime = parseFloat(targetTime);
		videoplayer.scrollIntoView(true);
		html5video.play();
		html5video.setAttribute('tabindex', '0');
	}
}

videoPlayerStarttimeLink.forEach(function(link){
	if (link.href.indexOf('#') < link.href.indexOf('&')) {
		link.addEventListener('click', function(event) {
			event.preventDefault();
			startVideoAtTimecode(link);
		});
	}
});



//
// Tag filters
//
const tags = $All('.js-tag')
if (tags.length > 0) {
	const events = $All('.event');
	tags.forEach(tag => {
		tag.addEventListener('change', e => {
			if (tag.id === '__all') {
				events.forEach(event => {
					event.classList.remove('hidden');
				});
			} else {
				const tagClass = `tag--${tag.value}`;
				events.forEach(event => {
					const eventClassList = event.classList;
					if (eventClassList.contains(tagClass)) {
						eventClassList.remove('hidden');
					} else {
						eventClassList.add('hidden');
					}
				});
			}
		}, false);
	});
}



//
// SVG Bugfix for IE 11
//
const svgItems = $All('.section .image__item svg');

function fixSvgSize(el) {
	const elAttrWidth  = el.getAttribute('width');
	const elAttrHeight = el.getAttribute('height');
	const elAttrRation = elAttrHeight / elAttrWidth;
	const elWidth  = el.getBoundingClientRect().width;
	const newHeight = Math.round(elWidth * elAttrRation) + 'px';
	el.style.height = newHeight;
}

if (document.documentElement.classList.contains('msie') && svgItems.length > 0) {
	svgItems.forEach(function(el){
		fixSvgSize(el);
	});

	window.addEventListener('resize', function(){
		svgItems.forEach(function(el){
			fixSvgSize(el);
		});
	});
}



//
// Change Cookie Settings
//
// https://gomakethings.com/how-to-update-localstorage-with-vanilla-javascript/
//
var cookieplaceholderSettingsButton = $All('.js-cookieplaceholder__button');

cookieplaceholderSettingsButton.forEach(function(button){
	button.addEventListener('click', function(event) {
		event.preventDefault();
		let existingStorageObject = localStorage.getItem('bannerStorage');
		existingStorageObject = existingStorageObject ? JSON.parse(existingStorageObject) : {};
		existingStorageObject.external = true;
		localStorage.setItem('bannerStorage', JSON.stringify(existingStorageObject));

		function setCookies(all, statistic, external) {
			const cookieEvent = document.createEvent('CustomEvent');
			cookieEvent.initCustomEvent('dwm-cookies', false, false, {
				all: all || (statistic && external),
				statistic,
				external,
			});
			document.dispatchEvent(cookieEvent);
		}

		setCookies(existingStorageObject.all, existingStorageObject.statistic, true);
	});
});







//
// MANNHEIM - KARLSRUHE
//
//
// Revealing SVG graphic
//
function revealingSvgLine() {
	$All('svg.redthread__background line[data-stage-scrollstart]').forEach(function(el){
		var svg = el.closest('svg');
		var breakpoint = svg.getAttribute('data-breakpoint');
		var elementTop = el.getBoundingClientRect().top + document.body.scrollTop;
		var viewportTop = document.body.scrollTop;
		var viewportBottom = viewportTop + window.innerHeight;
		var stageId = parseInt(el.getAttribute('data-stage-scrollstart'));

		if(elementTop < viewportTop){
			$All('svg.redthread__background[data-breakpoint="'+breakpoint+'"] g[data-stage="'+(stageId-1)+'"]').forEach(function(item){
				item.classList.add('visible', 'go', 'skip');
			});
			$All('svg.redthread__background[data-breakpoint="'+breakpoint+'"] g[data-stage="'+stageId+'"]').forEach(function(item){
				item.classList.add('visible', 'go');
			});
		} else if(elementTop > viewportTop && elementTop < viewportBottom){
			$All('svg.redthread__background[data-breakpoint="'+breakpoint+'"] g[data-stage="'+stageId+'"]').forEach(function(item){
				item.classList.add('visible');
			});
		}
	});
}


//
// Revealing SVG graphic only if its a good Browser
//
if (document.documentElement.classList.contains('no-msie')) {
	revealingSvgLine();

	window.addEventListener('scroll', function(e) {
		revealingSvgLine();
	});
}




var svgGroupItem = $All('svg.redthread__background g');
if (svgGroupItem !== null) {
	svgGroupItem.forEach(function(el){
		el.setAttribute('data-animation-count', 0);
	});
}




// Detect the End of Transition and Animation with the Help of JavaScript
// https://pineco.de/detect-the-end-of-transition-and-animation-with-the-help-of-javascript/
//
// Detecting CSS Animation Completion with JavaScript
// https://davidwalsh.name/css-animation-callback
//
function whichAnimationEvent() {
	var a;
	var el = document.createElement('fakeelement');
	var animations = {
		"animation" : "animationend",
		"oanimation" : "oanimationend",
		"msAnimation" : "msAnimationEnd",
		"webkitAnimation": "webkitAnimationEnd"
	}

	for (a in animations) {
		if (el.style[a] !== undefined) {
			return animations[a];
		}
	}
}


var svgGroupAnimate = $All('svg.redthread__background g .animate');
var animationEvent = whichAnimationEvent();

svgGroupAnimate.forEach(function(el){
	el.addEventListener(animationEvent, function(e){
		var currentCount = parseInt(el.closest('g').getAttribute('data-animation-count'))+1;
		var currentStage = parseInt(el.closest('g').getAttribute('data-stage'));
		var breakpoint = el.closest('svg').getAttribute('data-breakpoint');
		el.closest('g').setAttribute('data-animation-count', currentCount);

		if(currentCount >= el.closest('g').querySelectorAll('.animate').length) {
			$All('svg.redthread__background[data-breakpoint="'+breakpoint+'"]').forEach(function(item){
				item.querySelectorAll('g[data-stage="'+(currentStage+1)+'"]').forEach(function(element){
					element.classList.add('go');
				});
			});
		}
	});
});

function injectIframes() {
	$All('.js-iframe').forEach(function(iframeWrapper) {
		var iframe = document.createElement('iframe');
		iframe.className = 'iframe__iframe';
		iframe.src = iframeWrapper.getAttribute('data-src');
		iframeWrapper.replaceChild(iframe, iframeWrapper.firstElementChild)
	});
}

if (window.dwmCb && window.dwmCb.external) {
	injectIframes();
} else {
	document.addEventListener('dwm-cookies', function(e) {
		if (e.detail.external) {
			injectIframes();
		}
	}, false);
}


//
// Interactive Map
//

// Check if page has div with "overlay"-class
if($('.overlay')) {
	var station;
	var stationId;
	var prevStationId;
	var previousStation;
	var nextStationId;
	var nextStation;
	var hash;
	var counter = 0;
	var body = document.body;
	var markerId;
	var tooltip;
	var prevId;


	// Add event listener to all markers (cities, bridges etc.) on the interactive map
	$All('[id^="map-"]', $('#map-positions')).forEach(element => {
		element.addEventListener('click', event => {
			if(element.id !== "map-positions") {
				let mapId = element.id.substring(4);
				openOverlay(mapId);
			}
		});

		if($('html').classList.contains('no-touch')) {
			element.addEventListener('mouseover', event => {
				if(element.id !== "map-positions") {
					let mapId = element.id.substring(4);
					openTooltip(mapId);
				}
			});
		}
	});

	$All('.tooltip').forEach(element => {
		const name = element.className.match(/tooltip-([^ ]+)/)[1];
		element.addEventListener('click', event => {
			closeTooltip(name);
			openOverlay(name);
		});
	});

	// Add event listener to "close"-button ("X")
	$('.overlay__close').addEventListener('click', event => {
		closeOverlay();
	});

	// $('.station__text') // Unused?

	// Add event listener to "next station"-button (">")
	$('.overlay__prev').addEventListener('click', event => {
		previousOverlay();
	});

	// Add event listener to "previous station"-button ("<")
	$('.overlay__next').addEventListener('click', event => {
		nextOverlay();
	});

	$All('.station__prev-mobile').forEach(element => {
		element.addEventListener('click', event => {
			previousOverlay();
		});
	});

	$All('.station__next-mobile').forEach(element => {
		element.addEventListener('click', event => {
			nextOverlay();
		});
	});

	// Add event listener to "escape"-key on the keyboard if an overlay is opened
	document.addEventListener('keydown', function(event){
		if(event.key === "Escape") {
			if(hash.indexOf('#overlay-') > -1) {
				closeOverlay();
			}
		}
	})

	// Add event listener to page if hash changes in url
	window.addEventListener('hashchange', function() {
		if(location.hash) {

			// Open overlay if hash changes while still on the same page
			openViaHash();

			if(previousStation) {
				previousStation.classList.add('is-hidden');
			}
			if(nextStation) {
				nextStation.classList.add('is-hidden');
			}


		} else {
			closeOverlay();
		}
	}, false);

	// Open overlay if overlay is opened from differen page (e.g. via link "/karte#overlay-<city>")
	if(location.hash) {
		openViaHash();
	}


	let touchstartX = 0;
	let touchendX = 0;

	const gestureZone = $All('.station');

	gestureZone.forEach(element => {
		element.addEventListener('touchstart', function(event) {
			touchstartX = event.changedTouches[0].screenX;
		})
	}, false);

	gestureZone.forEach(element => {
		element.addEventListener('touchend', function(event) {
			touchendX = event.changedTouches[0].screenX;
			handleGesture();
		})
	}, false)

	function handleGesture() {
		if(window.innerWidth < 1024) {
			if ((touchendX < touchstartX) && ((touchstartX - touchendX) > 50)) {
				swipeLeft();
			}
			if ((touchendX > touchstartX) && ((touchendX - touchstartX) > 50)) {
				swipeRight();
			}
		}

	}

	// Calculate width of scrollbar to correctly position elements in overlay
	window.addEventListener('resize', function() {
		document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - $('.overlay').clientWidth) + "px");
	});

	// Recalculate position of the markers on scroll and window resize
	document.addEventListener('scroll', calcMarkerPos);
	window.onresize = calcMarkerPos;
}

// Open overlay via url/hash
function openViaHash() {
	hash = location.hash;
	if(hash.indexOf('#overlay-' > -1)) {
		stationId = hash.substring(9);
		if($('.overlay-' + stationId)) {
			openOverlay(stationId);
		}
	}
}

// General function to open an overlay
function openOverlay(id) {
	stationId = "overlay-" + id;
	station = document.getElementById(stationId);
	if (!station) return;
	station.classList.remove('is-hidden');
	checkPreviousAndNextOverlay(station);
	addIdToUrl(stationId);
	body.classList.add('no-scroll');
	$('.overlay').classList.remove('is-hidden');

	// Calculate width of scrollbar to position elements in overlay correctly
	document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - $('.overlay').clientWidth) + "px");
}

// General function to close an overlay
function closeOverlay() {
	station.classList.add('is-hidden');
	body.classList.remove('no-scroll');
	$('.overlay').classList.add('is-hidden');+
	history.replaceState("", document.title, window.location.pathname);
	pauseAllVideos();
}

// General function to open previous overlay if available
function previousOverlay() {
	if (!previousStation) {
		return closeOverlay();
	}
	station.classList.add('is-hidden');
	station = previousStation;
	stationId = station.id;
	checkPreviousAndNextOverlay(station);
	addIdToUrl(stationId);
	station.classList.remove('is-hidden');
	pauseAllVideos();
}

// General function to open next overlay if available
function nextOverlay() {
	if (!nextStation) {
		return closeOverlay();
	}
	station.classList.add('is-hidden');
	station = nextStation;
	stationId = station.id;
	checkPreviousAndNextOverlay(station);
	addIdToUrl(stationId);
	station.classList.remove('is-hidden');
	pauseAllVideos();
}

// Check if previous and/or next overlay exists
function checkPreviousAndNextOverlay () {

	// Check if previous overlay exists
	if((station.previousElementSibling) && (station.previousElementSibling.id.indexOf('overlay-') > -1)) {
		previousStation = station.previousElementSibling;
		prevStationId = previousStation.id;

		$('.overlay__prev').classList.remove('is-hidden');
		$('.overlay__prev-text').innerHTML = previousStation.querySelector('.station__title').innerHTML;

		station.querySelector('.station__prev-text').innerHTML = previousStation.querySelector('.station__title').innerHTML;
		station.querySelector('.station__prev-mobile').style.visibility = "visible";

	} else {
		previousStation = null;
		prevStationId = null;
		$('.overlay__prev').classList.add('is-hidden');
		station.querySelector('.station__prev-mobile').style.visibility = "hidden";
	};

	// Check if next overlay exists
	if((station.nextElementSibling) && (station.nextElementSibling.id.indexOf('overlay-') > -1)) {
		nextStation = station.nextElementSibling;
		nextStationId = nextStation.id;

		$('.overlay__next').classList.remove('is-hidden');
		$('.overlay__next-text').innerHTML = nextStation.querySelector('.station__title').innerHTML;

		station.querySelector('.station__next-text').innerHTML = nextStation.querySelector('.station__title').innerHTML;
		station.querySelector('.station__next-mobile').style.visibility = "visible";

	} else {
		nextStation = null;
		nextStationId = null;
		$('.overlay__next').classList.add('is-hidden');
		station.querySelector('.station__next-mobile').style.visibility = "hidden";
	};
};

// Open previous overlay by swiping
function swipeRight() {
	if(station.previousElementSibling) {
		station.classList.add('is-hidden');
		station = previousStation;
		stationId = station.id;
		checkPreviousAndNextOverlay(station);
		addIdToUrl(stationId);
		station.classList.remove('is-hidden');
		pauseAllVideos();
	}
}

// Open next overlay by swiping
function swipeLeft() {
	if(station.nextElementSibling) {
		station.classList.add('is-hidden');
		station = nextStation;
		stationId = station.id;
		checkPreviousAndNextOverlay(station);
		addIdToUrl(stationId);
		station.classList.remove('is-hidden');
		pauseAllVideos();
	}
}

// Add id of opened overlay as hash to url
function addIdToUrl(id) {
	window.location.hash = id;
}

// Remove hash from URL
function removeIdFromUrl() {
	location.href = location.href.split('#')[0];
}

// Calculate marker position to position tooltip correctly
function calcMarkerPos() {
	if (markerId !== undefined) {
		const boundingClientRect = $(markerId).getBoundingClientRect();
		const markerPos = {
			top: boundingClientRect.top + window.pageYOffset,
			left: boundingClientRect.left + (boundingClientRect.width / 2) + window.pageXOffset
		}
		return markerPos;
	}
}

// Open tooltip on mouseover based on given marker id
function openTooltip(id) {
	markerId = "#map-" + id;

	const $tooltip = $('.tooltip-' + id);

	if ($tooltip !== null) {
		// Hide all sibling tooltips
		$All('.tooltip', $tooltip.parentNode).forEach(curTooltip => {
			curTooltip.classList.add('is-hidden');
		});
		// Open current tooltip
		tooltip = ".tooltip-" + id;
		$tooltip.classList.remove('is-hidden');
		const markerPos = calcMarkerPos();
		$tooltip.style.top = markerPos.top + 'px';
		$tooltip.style.left = markerPos.left + 'px';
	}
}

// Close tooltip on mouseout based on given marker id
function closeTooltip(id) {
	let prevTooltip = "tooltip-" + id;

	if($('.' + prevTooltip)) {
		$('.' + prevTooltip).classList.add('is-hidden');
	}
}

window.onscroll = () => {
	if(window.innerWidth < 800) {
		$All('.tooltip').forEach(curTooltip => {
			curTooltip.classList.add('is-hidden');
		});
	}
}

// Close tooltip if clicked outside of element
document.addEventListener('click', function(event) {
	if (event.target.closest('.tooltips')) return;

	var openItem = $(tooltip);
	if (openItem !== null) {
		openItem.classList.add('is-hidden');
	}
});


//
// Blogpost Data
//
function blogpostCollapse(){

	//classes
	let c = {
		toggle: 'js-blogpost__readmore',
		collapse: 'js-blogpost__collapse',
		collapseOpenClass: 'blogpost__collapsed-content--open',
		toggleHiddenClass: 'blogpost__readmore--hidden'
	};

	//selectors
	let s = {
		blog: '.js-blog',
		toggle: '.' + c.toggle,
		collapse: '.' + c.collapse
	};

	let blog = document.querySelector(s.blog)
	let toggles = document.querySelectorAll(s.toggle);

	if (blog) {
		blog.addEventListener('click', (event) => {

			let toggle = event.target;
			if (toggle.classList.contains(c.toggle)) {
				event.preventDefault();
				let collapse = toggle.parentElement.parentElement.querySelector(s.collapse);
				// show content if collapsed content is available
				if (collapse.classList.contains(c.collapse)) {
					collapse.classList.add(c.collapseOpenClass);
					collapse.setAttribute('aria-hidden', false);
					collapse.tabIndex = 0;
					collapse.focus();
				}
				//hide button
				toggle.setAttribute('aria-expanded', 'true');
				toggle.disabled = true;
				toggle.tabIndex = -1;
				toggle.classList.add(c.toggleHiddenClass);
			}

		})
	}

	// only needed for posts which are not inside .js-blog eg. tester-page
	toggles.forEach( toggle => {
		toggle.addEventListener('click', event => {

			event.preventDefault();
			event.stopPropagation(); // no double execution when child of .js-log
			let collapse = toggle.parentElement.parentElement.querySelector(s.collapse);
			// show content if collapsed content is available
			if (collapse.classList.contains(c.collapse)) {
				collapse.classList.add(c.collapseOpenClass);
				collapse.setAttribute('aria-hidden', false);
				collapse.tabIndex = 0;
				collapse.focus();
			}
			//hide button
			toggle.setAttribute('aria-expanded', 'true');
			toggle.disabled = true;
			toggle.tabIndex = -1;
			toggle.classList.add(c.toggleHiddenClass);

		});
	});
}

blogpostCollapse();


if($('.js-showmenu__toggle')) {
	const burgerIcon = $('.js-showmenu__toggle');
	const menu = $('.js-menu');
	const body = document.body;
	const header = $('.header');
	var menuOpen = false;

	burgerIcon.addEventListener('click', function() {
		burgerIcon.getAttribute('aria-expanded') === "true" ? burgerIcon.setAttribute('aria-expanded', "true") : burgerIcon.setAttribute('aria-expanded', "false");

		!menu.classList.contains('animate') &&  menu.classList.add('animate');

		if(burgerIcon.getAttribute('aria-expanded') === "false") {
			menuOpen = true;
			burgerIcon.setAttribute('aria-expanded', "true");
		} else {
			menuOpen = false;
			burgerIcon.setAttribute('aria-expanded', "false");
		}

		if(!$('.showmenu__icon').classList.contains('animate')) {
			$('.showmenu__icon').classList.add('animate');
		}

		toggleMenu(menuOpen);

	});

	function toggleMenu(isOpen) {
		if(isOpen) {
			body.classList.add('menu--open');
			header.scrollIntoView(true);
		} else {
			body.classList.remove('menu--open');
		}
	}

	window.onresize = function() {
		if(window.innerWidth >= 800) {
			toggleMenu(false);
			burgerIcon.setAttribute('aria-expanded', "false");
			menu.classList.remove('animate');
		}
	};
}

if($('.karte')) {
	const karte = $('.karte');
	if ('IntersectionObserver' in window) {
		const observer = new IntersectionObserver(function(element) {
			if (element[0].isIntersecting) {
				element[0].target.classList.add('karte--animate');
			} else {
				element[0].target.classList.remove('karte--animate');
			}
		}, {
			threshold: [0.2],
		});
		observer.observe(karte);
	} else {
		console.log('No Intersection Observer Support');
	}
}




//
// DB Cookie Content Layer button in website footer
// https://consentlayer.bahn-x.de/quickstart#2-add-a-manage-consent-button-to-websites-footer
//
const cookieConsentMangerToogleButton = document.querySelectorAll('.dwm-cb-open-cookie-banner');
cookieConsentMangerToogleButton.forEach(function(button){
	button.addEventListener('click', function(event) {
		event.preventDefault();
		window.DB_CMP.showSecondLayer();
	});
});




//
//
//
var videoPlayerDbYoutube = $All('.js-db-youtube');

videoPlayerDbYoutube.forEach(function(video){

	video.querySelector('.js-videobutton').addEventListener('click', function(event) {
		// console.log(event.target);
		event.target.parentNode.parentNode.querySelector('.video__cover').remove();
		event.target.parentNode.remove();
	});

	video.querySelector('.js-videobuttonwrapper').addEventListener('click', function(event) {
		// console.log(event.target);
		if(!event.target.classList.contains('js-videobutton')) {
			event.target.parentNode.querySelector('.video__cover').remove();
			event.target.remove();
		}
	});


});
